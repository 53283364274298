import {provide, readonly, inject, InjectionKey, ref, Ref} from "vue";

// InjectionKeys
export const KEY_THEME: InjectionKey<Ref<string>> = Symbol("KEY_THEME");
export const KEY_SETTHEME: InjectionKey<(newTheme: string) => void> = Symbol("KEY_SETTHEME");
export const KEY_LAN: InjectionKey<Ref<string>> = Symbol("KEY_LAN");
export const KEY_SETLAN: InjectionKey<(newTheme: string) => void> = Symbol("KEY_SETLAN");
export const KEY_TOGGLELAN: InjectionKey<() => void> = Symbol("KEY_TOGGLELAN");

// create and provide reactive objects
export const initStore = () => {
    const theme = ref('light');
    const language = ref('en');

    function setTheme(t: string) {
        theme.value = t;
    }
    function setLanguage(lan: string) {
        language.value = lan;
    }
    function toggleLanguage() {
        language.value = language.value === 'en' ? 'de' : 'en';
    }

    provide(KEY_THEME, readonly(theme));
    provide(KEY_SETTHEME, setTheme);
    provide(KEY_LAN, readonly(language));
    provide(KEY_SETLAN, setLanguage);
    provide(KEY_TOGGLELAN, toggleLanguage);

    return {theme, setTheme, language, setLanguage, toggleLanguage};
};


// return object with injected values
export const useStore = () => ({
    theme: inject(KEY_THEME) as Ref<string>,
    setTheme: inject(KEY_SETTHEME),
    language: inject(KEY_LAN) as Ref<string>,
    setLanguage: inject(KEY_SETLAN),
    toggleLanguage: inject(KEY_TOGGLELAN)
});
