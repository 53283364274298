
    import { Options, Vue } from "vue-class-component";

    @Options({
        props: {
            msg: String
        }
    })
    export default class Footer extends Vue {
        msg!: string;
    }
