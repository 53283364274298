import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import {
    faBook,
    faBrain,
    faClock,
    faCode,
    faCoffee,
    faCompassDrafting,
    faDatabase,
    faEnvelope,
    faFlask,
    faGlobe,
    faHeart,
    faLocationDot,
    faPenNib,
    faSearch,
    faTabletAlt,
    faUsers,
    faVrCardboard,
    faCar,
    faMessage,
    faUniversity,
    faIndustry,
    faRocket,
    faFireFlameCurved,
    faLink,
    faCopy,
    faCircleHalfStroke,
    faFilePdf,
    faCubes,
    faLaptopCode,
    faInfoCircle,
    faInfo,
    faArrowUpRightFromSquare,
    faChartPie,
    faComments,
    faMobile,
    faTrainSubway,
    faShuffle,
    faXmark,
    faCaretDown,
    faBars,
    faUser,
    faSquare,
    faLightbulb,
    faSun,
    faBolt,
    faFire,
    faRuler,
    faHorseHead,
    faCookieBite,
    faIdBadge,
    faCheck,
    faCircle,
    faCircleDot,
    faPlayCircle,
    faStopCircle,
    faClipboardList,
    faQuoteLeft,
    faQuoteRight
} from '@fortawesome/free-solid-svg-icons';
import {
    faAngular,
    faLinkedin,
    faLinkedinIn,
    faPython, faSwift, faTwitter, faUnity, faVuejs
} from "@fortawesome/free-brands-svg-icons";
import {
    faKeyboard,
    faSquare as faSquareSolid
} from "@fortawesome/free-regular-svg-icons";

library.add(
    faCoffee,
    faLocationDot,
    faKeyboard,
    faEnvelope,
    faTabletAlt,
    faSearch,
    faDatabase,
    faFlask,
    faBook,
    faUsers,
    faClock,
    faGlobe,
    faCode,
    faCompassDrafting,
    faBrain,
    faHeart,
    faVrCardboard,
    faPenNib,
    faCar,
    faMessage,
    faTwitter,
    faUniversity,
    faIndustry,
    faRocket,
    faFlask,
    faFireFlameCurved,
    faLink,
    faCopy,
    faCircleHalfStroke,
    faFilePdf,
    faCubes,
    faLaptopCode,
    faInfoCircle,
    faInfo,
    faArrowUpRightFromSquare,
    faChartPie,
    faComments,
    faMobile,
    faTrainSubway,
    faFilePdf,
    faShuffle,
    faXmark,
    faCaretDown,
    faBars,
    faSquare,
    faUser,
    faSquareSolid,
    faLightbulb,
    faSun,
    faBolt,
    faFire,
    faRuler,
    faHorseHead,
    faCookieBite,
    faIdBadge,

    faPython,
    faVuejs,
    faSwift,
    faAngular,
    faUnity,
    faLinkedinIn,
    faLinkedin,

    faCheck,
    faCircle,
    faCircleDot,
    faPlayCircle,
    faStopCircle,
    faClipboardList,
    faQuoteLeft,
    faQuoteRight

);
import Vue3TouchEvents from "vue3-touch-events";

const app = createApp(App);
app.component("font-awesome-icon", FontAwesomeIcon)
    .use(router)
    .use(Vue3TouchEvents)
    .mount("#app");
