import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import Matt from "../views/matt/Matt.vue";
import ArticleXR from "../views/article-xr/ArticleXR.vue";
import Energy from "../views/energy/Energy.vue";
import Impressum from "../views/impressum/ImpressumView.vue";
import Trompete from "../views/trompete/Trompete.vue";
import ESDevSurvey from "../views/es-survey/ESDevSurvey.vue";
import Robot from "../views/robot/Robot.vue";
import Article from "@/views/article/Article.vue";

const routes: Array<RouteRecordRaw> = [
    {
        path: "/",
        name: "Matt",
        component: Matt
    },
    // {
    //     path: "/xr",
    //     name: "ArticleXR",
    //     component: ArticleXR
    // },
    // {
    //     path: "/watches",
    //     name: "Watches",
    //     component: Article
    // },
    // {
    //     path: "/energy",
    //     name: "Energy",
    //     component: Energy
    // },
    {
        path: "/impressum",
        name: "Impressum",
        component: Impressum,
        props: { section: 'imp'}
    },
    {
        path: "/privacy",
        name: "Privacy",
        component: Impressum,
        props: { section: 'privacy'}
    }
    // {
    //     path: "/trompete",
    //     name: "Trompete",
    //     component: Trompete
    // },
    // {
    //     path: "/essurvey",
    //     name: "ESDevSurvey",
    //     component: ESDevSurvey
    // },
    // {
    //     path: "/robot",
    //     name: "Robot",
    //     component: Robot
    // }
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

export default router;
