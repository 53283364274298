
    // hack for require not found...
    declare const require: any;
    import { defineComponent, PropType, ref, onMounted, watch, computed} from "vue";
    import {DocLink} from "@/types/c9.types";

    export default defineComponent({
        name: "Popup",
        components: {},
        emits: ['closeRequest'],
        props: {
            isVisible: Boolean,
            title: String,
            content: String,
            tags: Array as PropType<string[]>,
            docs: Array as PropType<DocLink[]>,
            images: Array as PropType<string[]>
        },
        setup(props, context) {
            const animationClass = ref("hidden");
            const centerElement = ref<HTMLDivElement | null>(null);
            const contentElement = ref<HTMLDivElement | null>(null);
            const loadedImages: any = {
                concept_platform: require('@/assets/concept_platform.png'),
                wc_concept: require('@/assets/wc_concept.png')
            };
            const imageSources = computed(() => {
                if(props.images) {
                    return props.images.map(img => loadedImages[img]);
                }
                return [];
            });
            // if(props.images) {
            //     props.images.forEach(img => {
            //         const str = require('@/assets/wc_concept.png');
            //         imageSources.push(str);
            //     });
            // }

            // cpm

            // watcher
            watch(() => props.isVisible, (curValue, preValue) => {
                animationClass.value = props.isVisible ? 'show' : 'hide';

                // scroll back
                if(props.isVisible && contentElement.value) {
                    contentElement.value.scrollTop = 0;
                }
            });

            function onSwipe(event: any) {
                close();
            }

            function download(url: string) {
                window.open(url, "_blank");
            }

            function close() {
                context.emit('closeRequest', {
                    id: 'popup'
                });
            }

            onMounted(() => {
                if(centerElement.value) {
                    centerElement.value.scrollTo(0,0);
                    // centerElement.value.addEventListener('animationstart', () => {
                    // });
                    centerElement.value.addEventListener('animationend', () => {
                        if(animationClass.value === 'show') {
                            animationClass.value = 'visible'
                        }else if(animationClass.value === 'hide') {
                            animationClass.value = 'hidden';
                        }
                    });
                }
            });

            return {
                close,
                download,
                onSwipe,
                centerElement,
                animationClass,
                imageSources,
                loadedImages
            }
        }
    });
